/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.0/dist/jquery.min.js
 * - /npm/bootstrap@5.3.1/dist/js/bootstrap.bundle.min.js
 * - /npm/lax.js@2.0.3/lib/lax.min.js
 * - /npm/ukiyojs@4.1.1/dist/ukiyo.min.js
 * - /npm/glightbox@3.2.0/dist/js/glightbox.min.js
 * - /npm/swiper@11.2.6/swiper-bundle.min.js
 * - /npm/instant.page@5.2.0/instantpage.min.js
 * - /npm/fittext.js@1.2.0/jquery.fittext.min.js
 * - /npm/motion@10.18.0/dist/motion.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
